import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import HomePage from './features/homepage';
import Login from './features/login';
import reportWebVitals from './reportWebVitals';
import NavBar from './components/NavBar';
import { Container } from '@mui/material';
import { FirebaseProvider } from './providers/Firebase';
import { FirebaseAuthProvider } from './providers/FirebaseAuth';
import Dashboard from './features/dashboard';
import { AddProperty } from './features/properties';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <div>
        <FirebaseProvider>
          <FirebaseAuthProvider>
            <Router>
              <NavBar/>
              <Container>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path='/properties/add-property' element={<AddProperty/>}/>
                </Routes>
              </Container>
            </Router>
          </FirebaseAuthProvider>
        </FirebaseProvider>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
