import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./Firebase";
import { onAuthStateChanged } from "firebase/auth";
import { LinearProgress } from "@mui/material";

interface Props {
    children?: ReactNode
}

interface FireBaseAuth {
    isAuth: () => boolean
    logout: () => void
}

const defaultAuth: FireBaseAuth = { isAuth: () => false, logout: () => {} };
const FirebaseAuthContext = createContext(defaultAuth);

const FirebaseAuthProvider = ({ children }: Props) => {
    const {auth } = useContext(FirebaseContext);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(auth.currentUser);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setUser(user)
            setLoading(false)
        });
    }, [auth]);

    const context: FireBaseAuth = {
        isAuth: () => user?true:false,
        logout: () => auth.signOut()
    }

    if (loading) {
        return (<LinearProgress />);
    }


    return (
        <FirebaseAuthContext.Provider value={context}>
            {children}
        </FirebaseAuthContext.Provider>
    );
}

export { FirebaseAuthProvider, FirebaseAuthContext };