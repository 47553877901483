import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function PropertyPreview() {
    const navigate = useNavigate();

    const NoProperties = () => (
        <TableRow>
        <TableCell colSpan={5}>
            <Typography align="center">                    
                No Properties addded
            </Typography>
        </TableCell>
    </TableRow>
    )

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} size="small" aria-label="property table">
                <TableHead>
                    <TableRow>
                        <TableCell>Property Name</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Next Booking</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <NoProperties/>
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={5}>
                            <Typography align="right">
                                <Button color="secondary" 
                                        variant="contained"
                                        onClick={() => navigate('/properties/add-property')}
                                >Add Property</Button>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

export default PropertyPreview