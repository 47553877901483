import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseAuthContext } from "../../../providers/FirebaseAuth";
import { Box, Button, Container, FormLabel, Grid2, OutlinedInput, Typography } from "@mui/material";


export default function AddProperty() {
    const navigate = useNavigate()
    const userContext = useContext(FirebaseAuthContext);
  
    useEffect(() => {
      if (!userContext.isAuth()) {
        navigate('/login');
      }
    } , [userContext, navigate]);

    return (
        <Container>
            <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px', paddingTop: '20px' }}}>
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                    Add Property
                </Typography>

                <Grid2 container spacing={1}>
                    <Grid2 container direction={"column"} size={{ xs: 12, md: 12 }}>
                        <FormLabel htmlFor="property-name" required>
                            Property Name
                        </FormLabel>
                        <OutlinedInput
                            id="property-name"
                            name="property-name"
                            type="name"
                            placeholder=""
                            autoComplete=""
                            required
                            size="small"
                        />
                    </Grid2>
                </Grid2>

                <Grid2 container spacing={1}  sx={{paddingTop: "10px"}}>
                    <Grid2 container direction={"column"} size={{ xs: 12, md: 12 }}>
                        <FormLabel htmlFor="address-line-1" required>
                            Address Line 1
                        </FormLabel>
                        <OutlinedInput
                            id="address-line-1"
                            name="address-line-1"
                            type="name"
                            placeholder=""
                            autoComplete=""
                            required
                            size="small"
                        />
                    </Grid2>
                </Grid2>
                
                <Grid2 container spacing={1}  sx={{paddingTop: "10px"}}>
                    <Grid2 container direction={"column"} size={{ xs: 12, md: 12 }}>
                        <FormLabel htmlFor="address-line-2" required>
                            Address Line 2
                        </FormLabel>
                        <OutlinedInput
                            id="address-line-2"
                            name="address-line-2"
                            type="name"
                            placeholder=""
                            autoComplete=""
                            required
                            size="small"
                        />
                    </Grid2>
                </Grid2>

                <Grid2 container spacing={1} sx={{paddingTop: "20px", justifyContent: "right"}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {}}
                    >
                        Add Property
                    </Button>
                </Grid2>
            </Box>
        </Container>
    )
}
