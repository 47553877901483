import { Box, Container, Grid2, Typography } from '@mui/material';
import PropertyPreview from './components/PropertiesPreview';
import Stats from './components/Stats';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { FirebaseAuthContext } from '../../providers/FirebaseAuth';

function Dashboard() {
  const navigate = useNavigate()
  const userContext = useContext(FirebaseAuthContext);

  useEffect(() => {
    if (!userContext.isAuth()) {
      navigate('/login');
    }
  } , [userContext, navigate]);

  return (
    <Container>
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px', paddingTop: '20px' }}}>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          Overview
        </Typography>
        <Grid2
          container
          spacing={2}
          columns={12}
        >
          <Stats/>      
        </Grid2>
      </Box >
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px', paddingTop: '20px' } }}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Properties
          </Typography>
            <PropertyPreview/>      
        </Box>
      </Container>
  );
}

export default Dashboard;