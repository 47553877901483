import { Card, CardContent, Chip, Stack, Typography } from "@mui/material";

export type StatCardProps = {
    title: string;
    value: string;
    interval: string;
    trend: 'up' | 'down' | 'neutral';
    data: number[];
  };

  const labelColors = {
    up: 'success' as const,
    down: 'error' as const,
    neutral: 'default' as const,
  };

function StatCard({ title, value, interval, trend, data }: StatCardProps) {
    const color = labelColors[trend];
    const trendValues = { up: '+25%', down: '-25%', neutral: '+0%' };
    return (
        <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
            <CardContent>
            <Typography component="h2" variant="subtitle2" gutterBottom>
                {title}
            </Typography>
            <Stack
                direction="column"
                sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
            >
                <Stack sx={{ justifyContent: 'space-between' }}>
                <Stack
                    direction="row"
                    sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <Typography variant="h4" component="p">
                    {value}
                    </Typography>
                    <Chip size="small" color={color} label={trendValues[trend]} />
                </Stack>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {interval}
                </Typography>
                </Stack>
            </Stack>
            </CardContent>
        </Card>
  );
}

export default StatCard;