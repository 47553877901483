import {auth as authui} from "firebaseui"
import {  GoogleAuthProvider } from "firebase/auth";

import { FirebaseContext } from "../../providers/Firebase";
import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseAuthContext } from "../../providers/FirebaseAuth";

function Login() {
    const { auth } = useContext(FirebaseContext);
    const authContext = useContext(FirebaseAuthContext);
    const navigate = useNavigate();
    const ui = useMemo(() => authui.AuthUI.getInstance() || new authui.AuthUI(auth), [auth]);

    useEffect(() => {
        if (authContext.isAuth()) {
            navigate('/dashboard');
        } else {
            ui.start('#firebaseui-auth-container', {
                callbacks: {
                    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                    return true;
                    },
                    signInFailure: function(error) {
                        console.log(error);
                    }
                },
                signInOptions: [
                    {
                        provider: GoogleAuthProvider.PROVIDER_ID,
                        signInMethod: GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD
                    }
                ],
                signInFlow: 'popup' // this avoid redirecting issues and it is ok for now.
            });
        }
    }, [auth, ui, authContext, navigate]);

    return (<div id="firebaseui-auth-container"/>)
}

export default Login;