
import { Container, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

import dasboard from './assets/dashboard.png';

function HomePage() {

  const  ImageBox = styled('div')(() => ({
    alignSelf: 'center',
    width: '100%',
    height: 400,
    marginTop: '10px',
    outline: '6px solid',
    outlineColor: 'hsla(220, 25%, 80%, 0.2)',
    borderColor: 'grey.500',
    border: '1px solid',
    boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
    backgroundSize: 'cover',
    backgroundImage: `url(${dasboard})`,
  }));

  return (
        <Box my={4}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: { xs: 7, sm: 10 },
              pb: { xs: 8, sm: 12 },
            }}
          >
            <Stack
              spacing={2}
              useFlexGap
              sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
            >
              <Typography
                variant="h1"
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'center',
                  fontSize: 'clamp(3rem, 10vw, 3.5rem)',
                }}
              >
                Easy
                <Typography
                  component="span"
                  variant="h1"
                  color='primary'
                  >
                    Let
                  </Typography>
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  color: 'text.secondary',
                  width: { sm: '100%', md: '80%' },
                }}
              >
                Streamline your property management with ease! Our platform simplifies property listings, automates bookings, 
                and optimizes rental operations, all in one place. From tenant communication to maintenance scheduling, 
                we empower property owners and managers with cutting-edge tools to maximize efficiency and enhance guest experiences. 
                
                Take your property management to the next level today!
              </Typography>

              <ImageBox />
            </Stack>


          </Container>
        </Box>
  );
}

export default HomePage;
