import { Button, Toolbar, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from '../providers/FirebaseAuth';
import { useContext } from 'react';


function NavBar() {
    const navigate = useNavigate();
    const userContext = useContext(FirebaseAuthContext);
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    EasyLet
                </Typography>
                { !userContext.isAuth() && 
                    <Button color="inherit" onClick={() => navigate('/login')}>Login</Button> 
                }
                { userContext.isAuth() && 
                    <>
                        <Button color="inherit" onClick={() => navigate('/dashboard')}>Dashboard</Button> 
                        <Button color="inherit" onClick={() => {
                                userContext.logout();
                                navigate('/login');
                            }}
                            >Logout</Button> 
                    </>
                }
            </Toolbar>
      </AppBar>
      
    )
}

export default NavBar;