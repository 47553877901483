import { createContext, ReactNode } from "react";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, browserSessionPersistence, getAuth, setPersistence } from "firebase/auth";


interface Firebase {
    auth: Auth
}

interface Props {
    children?: ReactNode
}

const app: FirebaseApp = initializeApp({
    apiKey: "AIzaSyDZ6zFz6V4kotHvOXM9-6hXx8xuZMq2-iI",
    authDomain: "easylet-47489.firebaseapp.com",
    projectId: "easylet-47489",
    storageBucket: "easylet-47489.firebasestorage.app",
    messagingSenderId: "326620956265",
    appId: "1:326620956265:web:fbdfdf55c9eb73bbb544f8",
    measurementId: "G-LMFBE8BSL2"
});

const fAuth: Auth = getAuth(app);
setPersistence(fAuth, browserSessionPersistence)
const defaultFirebase: Firebase = {auth: fAuth};

const FirebaseContext = createContext(defaultFirebase);

const FirebaseProvider = ({ children }: Props) => {
   return (
     <FirebaseContext.Provider value={defaultFirebase}>
       {children}
     </FirebaseContext.Provider>
   );
  }
  
  export { FirebaseContext, FirebaseProvider };