import StatCard from "./StatCard";

function Stats() {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });

    return (
        <>
            <StatCard title={`${month} Income`} value={'0£'} trend={'neutral'} interval={''} data={[0]} />
            <StatCard title={`${month} Expenses`} value={'0£'} trend={'neutral'} interval={''} data={[0]} />
        </>
    );
}

export default Stats;